import { css } from "@emotion/react";
import styled from "@emotion/styled";

import CommentIcon from "./comment-widget-icon.svg";

export const StyledButton = styled.button`
  z-index: 1;

  padding: 0;

  border: none;

  background-color: transparent;

  cursor: pointer;
`;

export const CountMessage = styled.span`
  position: absolute;
  inset-block-start: -10px;
  inset-inline-start: 16px;
  z-index: 0;

  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  min-inline-size: 16px;
  padding: 2px;

  color: #ffffff;
  font-size: 12px;
  line-height: 14px;

  border-radius: 2px;

  background-color: #eb4254;
`;

export const NoPostMessage = styled.div`
  position: absolute;
  inset-block-start: -29px;
  inset-inline-start: -2px;

  color: #001246;
  font-size: 18px;
  line-height: 21px;
`;

export const StyledCommentIcon = styled(CommentIcon)``;

export type ContainerProps = {
  $variant?: "light" | "dark";
};
export const Container = styled.div<ContainerProps>`
  position: relative;

  ${NoPostMessage} {
    ${props =>
      props.$variant === "dark"
        ? css`
            color: #ffffff;
          `
        : null}
  }

  ${StyledCommentIcon} {
    path {
      ${props =>
        props.$variant === "dark"
          ? css`
              fill: #ffffff;
            `
          : null}
    }
  }
`;
