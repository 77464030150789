import styled from "@emotion/styled";
import { fontRoboto } from "@product/scmp-sdk";

export const DrawerCard = styled.div``;

export const DrawerCardHeader = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  margin-block: 0 6px;
  margin-inline: 0;
`;

export const DrawerCloseContainer = styled.div`
  inline-size: 16px;
  block-size: 18px;

  cursor: pointer;
`;

export const DrawerCardTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  font-family: ${fontRoboto};
  line-height: 22px;
`;
