import { forwardRef } from "react";

import { Icon, StyledButton } from "./styles";

type Props = {
  className?: string;
  onClick?: () => void;
};

export const ArticleCommentsIcon = forwardRef<HTMLButtonElement, Props>(
  ({ className, onClick }, reference) => (
    <StyledButton className={className} ref={reference} onClick={onClick}>
      <Icon />
    </StyledButton>
  ),
);

ArticleCommentsIcon.displayName = "ArticleCommentsIcon";
