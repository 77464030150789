import { forwardRef } from "react";

import { CountMessage, Icon, StyledButton } from "./styles";

type Props = {
  className?: string;
  commentCount: number;
  onClick?: () => void;
};

export const ArticleCommentsButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, commentCount, onClick }, reference) => {
    const hasComment = commentCount > 0;
    return (
      <StyledButton
        $hasComment={hasComment}
        className={className}
        ref={reference}
        onClick={onClick}
      >
        {hasComment && <CountMessage>{commentCount}</CountMessage>}
        <Icon />
      </StyledButton>
    );
  },
);

ArticleCommentsButton.displayName = "ArticleCommentsButton";
